import * as client_hooks from '../../src/hooks.client.js';

export { matchers } from './client-matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63')];

export const server_loads = [];

export const dictionary = {
	"/": [5],
	"/admin": [6,[2]],
	"/admin/articles": [7,[2]],
	"/admin/articles/create-blog": [9,[2]],
	"/admin/articles/[slug]": [8,[2]],
	"/admin/calculator": [10,[2]],
	"/admin/coupons": [11,[2]],
	"/admin/favorite": [12,[2]],
	"/admin/favorite/[id]": [13,[2]],
	"/admin/invoices": [14,[2]],
	"/admin/notes": [15,[2]],
	"/admin/offers": [16,[2]],
	"/admin/offers/new": [20,[2]],
	"/admin/offers/[id]": [17,[2]],
	"/admin/offers/[id]/reissue": [18,[2]],
	"/admin/offers/[id]/update": [19,[2]],
	"/admin/orders": [21,[2]],
	"/admin/orders/import": [23,[2]],
	"/admin/orders/[id]": [22,[2]],
	"/admin/properties": [24,[2]],
	"/admin/properties/new": [26,[2]],
	"/admin/properties/[slug]": [25,[2]],
	"/admin/users": [27,[2]],
	"/admin/users/new": [29,[2]],
	"/admin/users/[id]": [28,[2]],
	"/b2b": [30],
	"/buying-process": [31],
	"/communitychronicle": [32,[3]],
	"/communitychronicle/category/[category_name]": [34,[3]],
	"/communitychronicle/[slug]": [33,[3]],
	"/contact": [35],
	"/faqs": [36],
	"/login": [37],
	"/lost-password": [38],
	"/me": [39,[4]],
	"/me/account-details": [40,[4]],
	"/me/invoices": [41,[4]],
	"/me/my-favorites": [42,[4]],
	"/me/offers": [43,[4]],
	"/me/offers/[id]": [44,[4]],
	"/me/orders": [45,[4]],
	"/me/orders/[id]": [46,[4]],
	"/me/payment-methods": [47,[4]],
	"/our-purpose": [48],
	"/our-story": [49],
	"/privacy": [50],
	"/properties": [51],
	"/properties/in-contract": [55],
	"/properties/landing/[slug]": [56],
	"/properties/sold": [57],
	"/properties/[slug]": [52],
	"/properties/[slug]/checkout": [53],
	"/properties/[slug]/checkout/success": [54],
	"/register": [58],
	"/reset": [59],
	"/sell": [60],
	"/support": [61],
	"/terms": [62],
	"/verify": [63]
};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};